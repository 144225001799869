import React, { useState, useEffect, useRef } from 'react';
import { ReactTyped } from "react-typed";

function AnimatedHeading() {
  const [text, setText] = useState('');
  const fullText = 'Fathiya Al-Maskary Chartered Accountants';
  const headingRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (text.length < fullText.length) {
        setText((prevText) => prevText + fullText[prevText.length]);
      } else {
        clearInterval(intervalId);
        // Start blinking after the heading is complete (if needed)
        // headingRef.current.classList.add('blinking-text');
      }
    }, 200); // Adjust delay as needed

    return () => clearInterval(intervalId);
  }, [fullText, text]);

  return (
    <h1 className="text-light" ref={headingRef}>
      {'"'}
      <ReactTyped strings={["Audit","Tax","Advisory","Risk","Technology","Fathiya Al-Maskary Chartered Accountants"]} typeSpeed={100} backSpeed={50} cursorChar=""
          showCursor={true} loop />
          {'"'}
    </h1>
  );
}

export default AnimatedHeading;