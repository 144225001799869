import Carousel from 'react-bootstrap/Carousel';
import React, { useState, useEffect, useRef } from 'react';
import AnimatedHeading from './AnimatedHeading';
import AnimatedParagraph from './AnimatedParagraph';

import './my.css';

import AOS from 'aos';
import Typed from 'react-typed';
import 'aos/dist/aos.css'; // Import AOS CSS
function App() {
/* HEADING  */


  


  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: 'ease-in-out',
      once: true
    });
  }, []);
  return (
    <>
    

<main className="main">
  {/* Hero Section */}
  <section id="home" className="hero section">
    <img
      src="assets/img/fathiya/home.webp"
      alt=""
      data-aos="fade-in"
      className="herobg"
    />
    <div className="container">
     
      
      <div className="row justify-content-center" data-aos="zoom-out">
        
        <div className="col-xl-7 col-lg-9 text-center">
        <AnimatedHeading />
        <AnimatedParagraph />
        </div>
      </div>
      <div className="text-center" data-aos="zoom-out" data-aos-delay={100}>
  <a href="#about" className="btn-get-started">
    Get Started
  </a>
</div>
      
    </div>
  </section>
  {/* /Hero Section */}
  <div>
    <Carousel fade>
      <Carousel.Item>
        <img className='d-block w-100 h-100' src='assets\img\resize-audit.jpg'></img>
        <Carousel.Caption className="d-flex flex-column align-items-center justify-content-center">
          <h3 className="text-center carousel-heading"><b>Auditing</b></h3>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img className='d-block w-100' src='assets\img\tax.jpg'></img>
        <Carousel.Caption>
        <h3 className="text-center carousel-heading"><b>Tax Services</b></h3>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img className='d-block w-100' src='assets\img\cimg.jpg'></img>
      
        <Carousel.Caption>
        <h3 className="text-center carousel-heading"><b>Consulting</b></h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
      
    </div>
  {/* About Section */}
  <section id="about" className="about section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2 className='aboutheading'>
        About Us
        <br />
      </h2>
      <p className='aboutpara' style={{ textAlign: 'justify' }}>
      An Omani Audit firm managed by a team of experts specialized in Auditing & Assurance, Accounting, Tax, financial, administrative, & economic consulting services. We have experience in Financial Standards (IFRS) & Auditing Standards. Also, we have expertise in the field of corporate tax & value-added tax (VAT).

      </p>
    </div>
    {/* End Section Title */}
    <div className="container">
    <div className="row justify-content-center" data-aos="zoom-out">
    <div className="col-xl-7 col-lg-9 text-center" data-aos="fade-up" data-aos-delay={100}>
     
  <img 
    src="assets/img/fathiya/aboutlogo.png" 
    alt="Description of the image" 
    style={{ 
      maxWidth: '100%', 
      height: 'auto' 
    }}
  />
</div>
<div className="text-center" data-aos="zoom-out" data-aos-delay={100}>
     <a href='#about-alt'><button type="button" class="btn btn-light">Read More</button></a>
      </div>
        
</div>
      
    </div>
  </section>
  {/* /About Section */}
  {/* MIssion Vision Section */}
  <section id="about-alt" className="about-alt section">
    <div className="container">
      <div className="row gy-4">
      <div
          className="col-lg-6 content"
          data-aos="fade-up"
          data-aos-delay={600}
        >
          <h1>
          VISION
          </h1>
          <p className="fst-italic">
          To be the first & most trusted choice for the companies in Oman, to handle their Audit & Consulting needs.  We aim to be recognized in the world as the leading Audit & Consulting Omani firm. And to make Fathiya Al-Maskary name linked with the accuracy and honesty in the field of auditing & consultancy.
          </p>
          <img 
    src="assets/img/fathiya/vissionlogo.png" 
    alt="Description of the image" 
    style={{ 
      maxWidth: '100%', 
      height: '300px  ' 
    }}
  />
        </div>
        <div
          className="col-lg-6 content"
          data-aos="fade-up"
          data-aos-delay={1000}
        >
          <h1>
          MISSION
          </h1>
          <p className="fst-italic">
          To be the most trusted, innovative & growing Audit firm in Oman, and to be the first destination for the talented Graduate in the country.          </p>
          <img 
    src="assets/img/fathiya/missionlogo.png" 
    alt="Description of the image" 
    style={{ 
      maxWidth: '100%', 
      height: '450px' 
    }}
  />
          
        </div>
      </div>
    </div>
  </section>
  {/* MIssion Vision Section */}
  
  {/* Clients Section */}
 
  {/* /Clients Section */}
  {/* Services Section */}
  <section id="services" className="services section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2>Services</h2>
      <p style={{ textAlign: 'justify' }}>
      Our family-owned account service has been serving the community. Our team has grown and includes experienced CAs and dedicated support to provide both personal and professional financial services. 
      </p>
    </div>
    {/* End Section Title */}
    <div className="container">
      <div className="row gy-4">
        <div
          className="col-lg-6 col-md-6"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="service-item item-cyan position-relative">
            <div className="icon">
              <svg
                width={100}
                height={100}
                viewBox="0 0 600 600"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke="none"
                  strokeWidth={0}
                  fill="#f5f5f5"
                  d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174"
                />
              </svg>
              <i class="bi bi-book"></i>
            </div>
            <a href="service-details.html" className="stretched-link">
              <h3>Taxation & VAT</h3>
            </a>
            <div className="container mt-4">
            <ul className="list-group">
              <li className="list-group-item">Tax Assessments</li>
              <li className="list-group-item">Corporate Tax</li>
              <li className="list-group-item">Withholding Tax</li>
              <li className="list-group-item">Preparation of Tax / VAT returns</li>
              <li className="list-group-item">VAT Services:</li>
              <ul>
                <li className="list-group-item">Registration</li>
                <li className="list-group-item">Impact Assessment & Implementation</li>
                <li className="list-group-item" >Audit</li>
                <li className="list-group-item">Advisory</li>
                <li className="list-group-item">Refunds & Recovery</li>
              </ul>
            </ul>
            </div>
          </div>
        </div>
        {/* End Service Item */}
        <div
          className="col-lg-6 col-md-6"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="service-item item-orange position-relative">
            <div className="icon">
              <svg
                width={100}
                height={100}
                viewBox="0 0 600 600"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke="none"
                  strokeWidth={0}
                  fill="#f5f5f5"
                  d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426"
                />
              </svg>
              <i class="bi bi-calculator-fill"></i>
            </div>
            <a href="service-details.html" className="stretched-link">
              <h3>Accounting</h3>
            </a>
            <div className="container mt-4">
            <ul className="list-group">
              <li className="list-group-item">Accounting Process Review</li>
              <li className="list-group-item">Bookkeeping Services</li>
              <li className="list-group-item">Accounting system setup for new businesses up to the financial statement.</li>
              <li className="list-group-item">On / Off Site Accounting </li>
              <li className="list-group-item">General ledger & Financial Statement Preparation</li>
              <li className="list-group-item">Budgeting & Forecasting</li>
              <li className="list-group-item">Project Accounting</li>
              <li className="list-group-item">Forensic Accounting</li>
              <li className="list-group-item">Payroll Services</li>
              <li className="list-group-item">Fixed Asset Verification</li>
              </ul>
              </div>
          </div>
        </div>
        {/* End Service Item */}
        <div
          className="col-lg-6 col-md-6"
          data-aos="fade-up"
          data-aos-delay={300}
        >
          <div className="service-item item-teal position-relative">
            <div className="icon">
              <svg
                width={100}
                height={100}
                viewBox="0 0 600 600"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke="none"
                  strokeWidth={0}
                  fill="#f5f5f5"
                  d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781"
                />
              </svg>
              <i class="bi bi-person-check-fill"></i>
            </div>
            <a href="service-details.html" className="stretched-link">
              <h3>Consulting & Advisory</h3>
            </a>
            <div className="container mt-4">
            <ul className="list-group">
              <li className="list-group-item">Feasibility study</li>
              <li className="list-group-item">Risk Assessment & Management Services</li>
              <li className="list-group-item">Periodic Compliance Services</li>
              <li className="list-group-item">Technology Consulting</li>
              <li className="list-group-item">GRC</li>
              <li className="list-group-item">Advisory Services:</li>
              <ul>
                <li className="list-group-item">Accounting </li>
                <li className="list-group-item">Financial</li>
                <li className="list-group-item">Management</li>
                <li className="list-group-item">Economic</li>
                <li className="list-group-item">Information Technology</li>
              </ul>
            </ul>
            </div>
          </div>
        </div>
        {/* End Service Item */}
        <div
          className="col-lg-6 col-md-6"
          data-aos="fade-up"
          data-aos-delay={400}
        >
          <div className="service-item item-red position-relative">
            <div className="icon">
              <svg
                width={100}
                height={100}
                viewBox="0 0 600 600"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke="none"
                  strokeWidth={0}
                  fill="#f5f5f5"
                  d="M300,503.46388370962813C374.79870501325706,506.71871716319447,464.8034551963731,527.1746412648533,510.4981551193396,467.86667711651364C555.9287308511215,408.9015244558933,512.6030010748507,327.5744911775523,490.211057578863,256.5855673507754C471.097692560561,195.9906835881958,447.69079081568157,138.11976852964426,395.19560036434837,102.3242989838813C329.3053358748298,57.3949838291264,248.02791733380457,8.279543830951368,175.87071277845988,42.242879143198664C103.41431057327972,76.34704239035025,93.79494320519305,170.9812938413882,81.28167332365135,250.07896920659033C70.17666984294237,320.27484674793965,64.84698225790005,396.69656628748305,111.28512138212992,450.4950937839243C156.20124167950087,502.5303643271138,231.32542653798444,500.4755392045468,300,503.46388370962813"
                />
              </svg>
              <i class="bi bi-card-checklist"></i>
            </div>
            <a href="service-details.html" className="stretched-link">
              <h3>Audit & Assurance Services</h3>
            </a>
            <div className="container mt-4">
            <ul className="list-group">
              <li className="list-group-item">Internal & External Audit</li>
              <li className="list-group-item">Financial Audit Services</li>
              <li className="list-group-item">Compliance Audit</li>
              <li className="list-group-item">Management Audit</li>
              <li className="list-group-item">IT Audit</li>
              <li className="list-group-item">HR Audit</li>
              <li className="list-group-item">Fraud Investigation</li>
            </ul>
            </div>
            <a href="service-details.html" className="stretched-link" />
          </div>
        </div>
        {/* End Service Item */}
      </div>
    </div>
  </section>
  {/* /Services Section */}
  {/* Call To Action Section */}
  {/* <section id="call-to-action" className="call-to-action section">
    <div className="container">
      <div
        className="row justify-content-center"
        data-aos="zoom-in"
        data-aos-delay={100}
      >
        <div className="col-xl-10">
          <div className="text-center">
            <h3>Company Executive Summary</h3>
            <p style={{ textAlign: 'justify' }}>
            An Omani Audit firm managed by a team of experts specialized in Auditing & Assurance, Accounting, Tax, financial, administrative, & economic consulting services. We have experience in Financial Standards (IFRS) & Auditing Standards. Also, we have expertise in the field of corporate tax & value-added tax (VAT). Our team has the following qualifications:
MBA – CISA – CFE – PMP – CDPSE – CICS – CA – Oman VAT Diploma

            </p>
            <a className="cta-btn" href="#contact">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  </section> */}
{/* REGISTER*/}


  {/* /Call To Action Section */}
  {/* Team Section */}
  {/* /Team Section */}
  {/* Pricing Section */}
  
  {/* /Pricing Section */}
  {/* Faq Section */} 
  {/* /Faq Section */}
  {/* Contact Section */}
  <section id="contact" className="contact section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2>Contact</h2>
      <p>
      Reach out to us today and let's start a conversation about your financial needs.
      </p>
    </div>
    {/* End Section Title */}
    <div className="container" data-aos="fade-up" data-aos-delay={100}>
      {/* <div className="mb-4" data-aos="fade-up" data-aos-delay={200}>
        <iframe
          style={{ border: 0, width: "100%", height: 270 }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675.9914420431646!2d57.530688772607526!3d22.876774533474684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8efb134cf01f3b%3A0x30929d803de47f05!2sNizwa%20firq!5e0!3m2!1sen!2sus!4v1720264777483!5m2!1sen!2sus"
          frameBorder={0}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div> */}
      {/* End Google Maps */}
      <div className="row gy-4">
        <div className="col-lg-4">
          <div
            className="info-item d-flex"
            data-aos="fade-up"
            data-aos-delay={300}
          >
            <i className="bi bi-geo-alt flex-shrink-0" />
            <div>
              <h3>Address</h3>
              <p>Muscat, Sultanate of Oman<br/></p>
            </div>
          </div>
          {/* End Info Item */}
          <div
            className="info-item d-flex"
            data-aos="fade-up"
            data-aos-delay={400}
          >
            <i className="bi bi-telephone flex-shrink-0" />
            <div>
              <h3>Call Us</h3>
              <p> +968-90933404</p>
            </div>
          </div>
          {/* End Info Item */}
          <div
            className="info-item d-flex"
            data-aos="fade-up"
            data-aos-delay={500}
          >
            <i className="bi bi-envelope flex-shrink-0" />
            <div>
              <h3>Email Us</h3>
              <p><a>info@fmcharteredaccountants.com</a></p>
            </div>
          </div>
          {/* End Info Item */}

          {/* End Info Item */}
          <div
            className="info-item d-flex"
            data-aos="fade-up"
            data-aos-delay={500}
          >
            <a href='#'><i className="bi bi-linkedin flex-shrink-0" /></a>
            
            <a href="#"><i className="bi bi-instagram flex-shrink-0" /></a>
            <a href="#"><i className="bi bi-twitter-x flex-shrink-0" /></a>
            
          </div>
          {/* End Info Item */}


        </div>
        <div className="col-lg-8">
          <form
            action="forms/contact.php"
            method="post"
            className="php-email-form"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <div className="row gy-4">
              <div className="col-md-6">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Your Name"
                  required=""
                />
              </div>
              <div className="col-md-6 ">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Your Email"
                  required=""
                />
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  placeholder="Subject"
                  required=""
                />
              </div>
              <div className="col-md-12">
                <textarea
                  className="form-control"
                  name="message"
                  rows={6}
                  placeholder="Message"
                  required=""
                  defaultValue={""}
                />
              </div>
              <div className="col-md-12 text-center">
                <div className="loading">Loading</div>
                <div className="error-message" />
                <div className="sent-message">
                  Your message has been sent. Thank you!
                </div>
                <button type="submit">Send Message</button>
              </div>
            </div>
          </form>
        </div>
        {/* End Contact Form */}
      </div>
    </div>
  </section>
  {/* /Contact Section */}
</main>






  </>
  );
}

export default App;
